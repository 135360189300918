// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/reset-css/reset.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./font/benzin-medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./font/benzin-regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./font/benzin-extrabold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
@font-face {
    font-family: "Museo";
    src: url('./font/museo.woff2') format("woff2")
}

@font-face {
    font-family: "Museo Sans Cyrl";
    src: url('./font/MuseoSansCyrl/MuseoSansCyrl-300.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Museo Sans Cyrl";
    src: url('./font/MuseoSansCyrl/MuseoSansCyrl-700.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Museo Sans Cyrl";
    src: url('./font/MuseoSansCyrl/MuseoSansCyrl-900.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

*/

@font-face {
    font-family: "Benzin Medium";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
    font-family: "Benzin Regular";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
    font-family: "Benzin ExtraBold";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

* {
    font-family: "Inter", sans-serif !important;
    font-weight: 300;
    box-sizing: border-box !important;
}
html {
    overflow-x: hidden;
    height: 100%;
}
body {
    overflow-x: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAKA;;;;;;;;;;;;;;;;;;;;;;;;;CAyBC;;AAED;IACI,4BAA4B;IAC5B,4CAAoC;AACxC;;AAEA;IACI,6BAA6B;IAC7B,4CAAqC;AACzC;;AAEA;IACI,+BAA+B;IAC/B,4CAAuC;AAC3C;;AAEA;IACI,2CAA2C;IAC3C,gBAAgB;IAChB,iCAAiC;AACrC;AACA;IACI,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,kBAAkB;AACtB","sourcesContent":["@import \"~reset-css\";\n\n@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&display=swap');\n\n\n/*\n@font-face {\n    font-family: \"Museo\";\n    src: url('./font/museo.woff2') format(\"woff2\")\n}\n\n@font-face {\n    font-family: \"Museo Sans Cyrl\";\n    src: url('./font/MuseoSansCyrl/MuseoSansCyrl-300.woff') format('woff');\n    font-weight: 300;\n    font-style: normal;\n}\n@font-face {\n    font-family: \"Museo Sans Cyrl\";\n    src: url('./font/MuseoSansCyrl/MuseoSansCyrl-700.woff') format('woff');\n    font-weight: 500;\n    font-style: normal;\n}\n@font-face {\n    font-family: \"Museo Sans Cyrl\";\n    src: url('./font/MuseoSansCyrl/MuseoSansCyrl-900.woff') format('woff');\n    font-weight: 700;\n    font-style: normal;\n}\n\n*/\n\n@font-face {\n    font-family: \"Benzin Medium\";\n    src: url('./font/benzin-medium.ttf');\n}\n\n@font-face {\n    font-family: \"Benzin Regular\";\n    src: url('./font/benzin-regular.ttf');\n}\n\n@font-face {\n    font-family: \"Benzin ExtraBold\";\n    src: url('./font/benzin-extrabold.ttf');\n}\n\n* {\n    font-family: \"Inter\", sans-serif !important;\n    font-weight: 300;\n    box-sizing: border-box !important;\n}\nhtml {\n    overflow-x: hidden;\n    height: 100%;\n}\nbody {\n    overflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
