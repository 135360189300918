const questions = [
    // первая часть
    {
        title: 'Что я могу предложить команде:',
        questions: [
            'Я думаю, что в состоянии быстро воспринимать и использовать новые  возможности.',

            'Я легко кооперируюсь с людьми разных типов.',

            'Один из моих главных активов – продуцировать новые идеи.',

            'Я способен вовлекать людей, которые, по моему мнению, могут внести большой  вклад в достижение групповых целей.',

            'Мои личные способности – эффективно доводить дело до самого конца.',

            'Я не представляю себе даже временного снижения своей популярности, даже если  это приведет к увеличению прибыли.',

            'Обычно я чувствую, что реалистично и что дееспособно.',

            'Я способен предложить весомые аргументы в пользу другой линии действий, не  провоцируя при этом предубеждений и предвзятости.'
        ]
    },
    // вторая часть
    {
        title: 'Что характеризует меня как члена команды:',
        questions: [
            'Я чувствую себя неуютно на собраниях, даже если они четко структурированы и  продуманно организованы.',

            'Я склонен полагаться на людей, которые хорошо аргументируют свою точку  зрения еще до того, как она была всесторонне обсуждена.',

            'Когда группа обсуждает новые идеи, я склонен слишком много говорить.',

            'Мои личные отношения мешают мне поддерживать коллег с энтузиазмом.',

            'Когда надо сделать какое-либо дело, некоторые люди считают, что я действую  агрессивно и авторитарно.',

            'Я затрудняюсь брать на себя лидерскую роль, может, потому, что слишком  чувствителен к чувствам и настроениям группы.',

            'У меня есть склонность настолько увлекаться собственными идеями, что я  забываю о том, что происходит вокруг.',

            'Мои коллеги считают, что я слишком забочусь о незначительных деталях и боюсь  риска, что дело может быть испорчено.'
        ]
    },
    // третья часть
    {
        title: 'Когда я работаю с другими на проекте:',
        questions: [
            'Я могу хорошо влиять на других людей, при этом не оказывая на них сильного  давления.',

            'Мое шестое чувство подсказывает и предохраняет меня от ошибок и инцидентов,  которые иногда случаются из-за небрежности.',

            'Во имя достижения главных целей я готов ускорять события, не тратя время на  обсуждение.',

            'От меня всегда можно ожидать чего-то оригинального.',

            'Я всегда готов поддержать хорошее предложение, которое принесет выгоду всем.',

            'Я постоянно отслеживаю последние идеи и новейшие достижения.',

            'Я думаю, что мои способности к суждениям и оценкам могут внести большой  вклад в принятие правильных решений.',

            'На меня всегда можно положиться на завершающем этапе работы.'
        ]
    },
    // четвертая часть
    {
        title: 'Мое отношение и интерес к групповой работе:',
        questions: [
            'Я искренне желаю узнать своих коллег получше.',

            'Я не боюсь ни оспаривать точку зрения другого человека, ни остаться в  меньшинстве.',

            'Обычно я могу доказать несостоятельность неудачного предложения.',

            'Я думаю, что я способен хорошо выполнять любую функцию ради выполнения  общего плана.',

            'Часто я избегаю очевидных решений и прихожу вместо этого к неожиданным  решениям проблемы.',

            'Я стремлюсь все, что делаю, доводить до совершенства.',

            'Я готов использовать контакты вне группы.',

            'Хотя я всегда открыт для восприятия различных точек зрения, я не испытываю  трудностей при принятии решения.'
        ]
    },
    // пятая часть
    {
        title: 'Я чувствую удовлетворение от работы, потому что:',
        questions: [
            'Мне нравится анализировать ситуации и оценивать возможные направления  деятельности.',

            'Мне интересно находить практические пути решения проблемы.',

            'Мне приятно чувствовать, что я помогаю созданию хороших отношений на работе.',

            'Часто я имею сильное влияние на принимаемые решения.',

            'Я имею открытые, дружеские отношения с людьми, которые могут предложить  что-то новенькое.',

            'Я могу убеждать людей в необходимости определенной линии действий.',

            'Я чувствую себя хорошо дома, когда могу уделить максимум внимания заданию.',

            'Я люблю работать с чем-то, что стимулирует мое воображение.'
        ]
    },
    // шестая часть
    {
        title: 'Когда задание трудное и незнакомое:',
        questions: [
            'Я откладываю дело на время и размышляю над проблемой.',

            'Я готов сотрудничать с людьми, которые более позитивно и с большим  энтузиазмом относятся к проблеме.',

            'Я пытаюсь сделать задание проще, подыскивая людей в группе, которые могут  взять на себя решение части проблемы.',

            'Мое врожденное ощущение времени позволяет мне выдерживать сроки  выполнения задания.',

            'Я думаю, мне удастся сохранить ясность мысли и спокойствие.',

            'Даже под давлением внешних обстоятельств я не отступаю от цели.',

            'Я готов взять лидерские обязанности на себя, если чувствую, что группа не  прогрессирует.',

            'Я бы начал дискуссию с целью стимулировать появление новых мыслей,  способствующих решению проблемы.'
        ]
    },
    // седьмая часть
    {
        title: 'Проблемы, возникающие при работе в группе:',
        questions: [
            'Я склонен выражать свое нетерпение по отношению к людям, которые стоят на  пути развития прогресса (мешают).',

            'Другие могут критиковать меня за то, что я слишком аналитичен и не подключаю  интуицию.',

            'Мое желание убедиться в том, что работа выполняется с высоким качеством,  может иногда привести к задержке.',

            'Мне быстро все надоедает, и я полагаюсь на то, что кто-то из группы стимулирует  мой интерес.',

            'Мне трудно приступить к решению задачи, не имея четкой цели.',

            'Иногда мне трудно объяснить и описать проблему в комплексе.',

            'Я знаю, что я требую от других того, что сам не могу выполнить.',
            
            'Я затрудняюсь выражать собственное мнение, когда нахожусь в очевидной  оппозиции к большинству.'
        ]
    },
];

const questionLength = questions.length;

export { questions, questionLength };